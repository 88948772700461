import React, { useEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';
import DisplayItemV3 from './DisplayItemV3';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

//Read for more about CSS Grid layouts: https://mui.com/system/grid/

type DisplaySectionParams = {
  sectionNumber: string;
};


const DisplayTV1 = () => {
  const [menuData, setMenuData] = useState<Array<UntappdMenuItem>>();
  let { sectionNumber } = useParams<DisplaySectionParams>();
  const [itemStart, setItemStart] = useState<number>(1);
  const [itemEnd, setItemEnd] = useState<number>(17);

  async function getMenuData(): Promise<Array<UntappdMenuItem>> {
    var unTappdItems: Array<UntappdMenuItem> = [];
    var items: Array<UntappdMenuItem> = [];
    

    fetch(process.env.PUBLIC_URL + '/untappd-menu.json')
      .then((r) => r.json())
      .then((data: UntappdMenuContainer) => {
        //console.log(JSON.stringify(data));
        (data as UntappdMenuContainer).menu.sections.forEach((section) => {
          unTappdItems = unTappdItems.concat(section.items);
        });

        const beerSections = (data as UntappdMenuContainer).menu.sections.filter(section => section.name === "Beer");
        beerSections.forEach((section) => {
          unTappdItems = unTappdItems.concat(section.items);
        });


        unTappdItems.forEach(x => x.tap_number_int = parseInt(x.tap_number));
        for (var i = 1; i < 33; i++)
        {
          var found = unTappdItems.filter(x => x.tap_number_int == i);
          if (found.length > 0)
          {
            items[i] = found[0];
          }
          else
          {
            var blank : UntappdMenuItem = { id: i, description: i.toString(), style: "", tap_number: i.toString(), tap_number_int: i, name: "", type: "", abv: "", section_id: 0, containers: [], label_image: "" };
            items[i] = blank;
          }
        }
        //items = items.filter((item) => item.tap_number); //.sort((item) => item.tap_number_int ?? 1000);
        console.log(JSON.stringify(items));
        setMenuData(items);
        return items;
      })

    return items;
  }

  function transposeTo3Column(items : Array<UntappdMenuItem>) : Array<UntappdMenuItem> {
    var threeCol: Array<UntappdMenuItem> = [];

    threeCol[1] = items[0];
    threeCol[2] = items[5];
    threeCol[3] = items[10];

    threeCol[4] = items[1];
    threeCol[5] = items[6];
    threeCol[6] = items[11];

    threeCol[7] = items[2];
    threeCol[8] = items[7];
    threeCol[9] = items[12];

    threeCol[10] = items[3];
    threeCol[11] = items[8];
    threeCol[12] = items[13];

    threeCol[13] = items[4];
    threeCol[14] = items[9];
    threeCol[15] = items[14];

    console.log(JSON.stringify(threeCol));
    return threeCol;
  }

  useEffect(() => {
    if (sectionNumber == "3")
    {
      console.log("Section#" + sectionNumber);
      setItemStart(16);
      setItemEnd(32);
    }

    const m = getMenuData(); //run it upon page load

    //reload menu data every 60 seconds
    setInterval(() => {
      const m = getMenuData(); //call function to read in the json file and set menu data object
    }, 300 * 1000); //how often to pull new data in milliseconds

    }, []);


  return (  
      <main style={{backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em'}}>
        {/* Hero unit */}
        <ThemeProvider
            theme={{
              palette: {
                primary: {
                  sour: '#DB523A',
                  IPA: '#5BB165',
                  lager: '#E7A550',
                  stout: '#782382',
                  other: '#3E1FCE'
                },
              },
            }}
        >
        <Container sx={{ py: 0.0, px: 0 }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridAutoFlow: "row", gridTemplateColumns: '492px 492px 492px', columnGap: 1, rowGap: 1, px: 0 }}>
            {menuData && transposeTo3Column(menuData.slice(itemStart, itemEnd)).map((menu_item) => <DisplayItemV3 key={menu_item.id} menuItem={menu_item} ></DisplayItemV3>)}
          </Box>
        </Container>
        <Container sx={{ py: 0.0, bgcolor: '#333', color: '#fff', height: '30px', width: '100%', bottom: '0px', position: 'absolute' }} maxWidth={false}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto auto auto auto auto', gridTemplateRows: 'auto', columnGap: 2, rowGap: 1 }}>
                <Box><Typography sx={{ color: 'white', textAlign: 'center'}} variant="h5" component="h5">Beer Styles:</Typography></Box>
                <Box sx={{ bgcolor: 'primary.IPA', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>IPA</Box>
                <Box sx={{ bgcolor: 'primary.sour', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>Sour</Box>
                <Box sx={{ bgcolor: 'primary.lager', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>Lager</Box>
                <Box sx={{ bgcolor: 'primary.stout', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>Stout</Box>
                <Box sx={{ bgcolor: 'primary.other', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>Other</Box>
            </Box>
        </Container>
        </ThemeProvider>
      </main>
  );
}

export default DisplayTV1;
