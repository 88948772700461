import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';
import MobileDisplayItemV3 from './MobileDisplayItemV3';
import Typography from '@mui/material/Typography';
import { Routes, Route, useParams } from 'react-router-dom';
import MobileFoodItem from './MobileFoodItem';
import { FoodMenuItem } from './menu-types';
import { dir } from 'console';

//Read for more about CSS Grid layouts: https://mui.com/system/grid/

const espresso: FoodMenuItem = { id: 1, name: 'Espresso (2oz)', style: 'Espresso', price: 3, description: 'Espresso comes with a side of seltzer water.' }
const cortado: FoodMenuItem = { id: 2, name: 'Cortado (4oz)', style: 'Espresso', price: 4, description: '' }
const cappuccino: FoodMenuItem = { id: 3, name: 'Cappuccino (6oz)', style: 'Espresso', price: 4.5, description: '' }
const latte: FoodMenuItem = { id: 4, name: 'Latte (12oz)', style: 'Espresso', price: 5, description: '' }

const houseCoffee8: FoodMenuItem = { id: 11, name: 'House Drip Coffee (8oz)', style: 'Batch', price: 2, description: '' }
const houseCoffee12: FoodMenuItem = { id: 12, name: 'House Drip Coffee (12oz)', style: 'Batch', price: 3, description: '' }
const baristaCoffee8: FoodMenuItem = { id: 13, name: 'Barista Pick (8oz)', style: 'Batch', price: 3, description: '' }
const baristaCoffee12: FoodMenuItem = { id: 14, name: 'Barista Pick (12oz)', style: 'Batch', price: 4, description: '' }
const coldBrew12: FoodMenuItem = { id: 15, name: 'Cold Brew (12oz)', style: 'Batch', price: 4, description: '' }
const theObvious: FoodMenuItem = { id: 16, name: 'The Obvious (12oz)', style: 'Batch', price: 4, description: 'Cold Brew sweetened with vanilla and cream' }

const flavors: FoodMenuItem = { id: 21, name: 'House-made flavor syrups and bitters', style: 'Modifiers', price: 0.50, description: '' }
const oatMilk: FoodMenuItem = { id: 22, name: 'Oat Milk', style: 'Modifiers', price: 0.75, description: '' }
const extraShot: FoodMenuItem = { id: 23, name: 'Extra Espresso Shot', style: 'Modifiers', price: 1, description: '' }

const saturdayMorning: FoodMenuItem = { id: 31, name: 'Saturday Morning Cartoons', style: 'Specials', price: 5.5, description: 'Rotating cereal oat milk flavors with 2oz of espresso and a dash of vanilla' }
const angryGrad: FoodMenuItem = { id: 32, name: 'The Angry Grad', style: 'Specials', price: 5.5, description: 'Espresso Tonic, Orange Peel, Angustura Bitters' }
const thePhD: FoodMenuItem = { id: 33, name: 'The PhD', style: 'Specials', price: 6, description: 'Espresso Tonic with a Seasonal Suryp, Orange Peel, Angostura Bitters' }
const notMilk: FoodMenuItem = { id: 31, name: 'Not Milk', style: 'Specials', price: 5.5, description: 'Caramel, Black Pepper, Oat Milk Cappuccino' }
const cardyB: FoodMenuItem = { id: 32, name: 'Cardy B', style: 'Specials', price: 5.5, description: 'Cardamom, Black Pepper Latte' }
const cocoCaliente: FoodMenuItem = { id: 33, name: 'Coco Caliente', style: 'Specials', price: 5.5, description: 'Spicy Mocha Latte' }
const americola: FoodMenuItem = { id: 33, name: 'Americola', style: 'Specials', price: 6.5, description: 'House-made Cola, Espresso' }

const mimosa: FoodMenuItem = { id: 31, name: 'Mimosa', style: '21+', price: 5.5, description: 'ceral milk, Ology G.O.A.T. cream liqueur and 2oz of espresso served over ice' }
const french75: FoodMenuItem = { id: 32, name: 'French 75', style: '21+', price: 8.5, description: 'espresso tonic with Ology gin' }
const bloodyMary: FoodMenuItem = { id: 33, name: 'Bloody Mary', style: '21+', price: 6.5, description: 'Ology cream liqueur, espresso and a float of Ology Bourbon' }
const irishCoffee: FoodMenuItem = { id: 33, name: 'Irish Coffee', style: '21+', price: 8.5, description: 'cold brew topped with Ology cream liqueur' }
const adultSwim: FoodMenuItem = { id: 31, name: 'Adult Swim', style: '21+', price: 8.5, description: 'ceral milk, Ology G.O.A.T. cream liqueur and 2oz of espresso served over ice' }
const officeHours: FoodMenuItem = { id: 32, name: 'Office Hours', style: '21+', price: 8.5, description: 'espresso tonic with Ology gin' }
const twentyLeagues: FoodMenuItem = { id: 33, name: '20k Leagues', style: '21+', price: 7.5, description: 'Ology cream liqueur, espresso and a float of Ology Bourbon' }
const blatantlyObvious: FoodMenuItem = { id: 33, name: 'Blatantly Obvious', style: '21+', price: 6.5, description: 'cold brew topped with Ology cream liqueur' }

const breakfastAuGratin: FoodMenuItem = { id: 33, name: 'Breakfast Au Gratin', style: 'Cafe Food', price: 8, description: 'cold brew topped with Ology cream liqueur' }
const coffeeCake: FoodMenuItem = { id: 33, name: 'Coffee Cake', style: 'Cafe Food', price: 4, description: 'Cinnamon Crum or Almond Chocolate' }
const muffin: FoodMenuItem = { id: 33, name: 'Muffin', style: 'Cafe Food', price: 4, description: 'Blueberry, Apple Cinnamon, or Double Chocolate' }
const bagel: FoodMenuItem = { id: 33, name: 'Bagel with Cream Cheese', style: 'Cafe Food', price: 4, description: 'Everything Bagel with a side of Cream Cheese' }
const biscotti: FoodMenuItem = { id: 33, name: 'Biscotti', style: 'Cafe Food', price: 2.5, description: 'Rotating Flavors' }

const milk: FoodMenuItem = { id: 31, name: 'Milk', style: 'Specials', price: 3, description: 'Whole, Chocolate, or Vanilla' }
const juice: FoodMenuItem = { id: 32, name: 'Juice', style: 'Specials', price: 3, description: 'Orange or Cranberry' }
const hotChocolate: FoodMenuItem = { id: 33, name: 'Hot Chocolate', style: 'Specials', price: 4, description: 'Milk with house-made Mocha' }

const londonFog: FoodMenuItem = { id: 33, name: 'London Fog', style: 'Specials', price: 4.5, description: 'Earl Grey Tea, Vanilla, Steamed Milk' }
const soulChaiTea: FoodMenuItem = { id: 33, name: 'Soul Chai Tea', style: 'Specials', price: 4.5, description: 'Chai, Vanilla, Steamed Milk (Decaf Available)' }
const matchaLattee: FoodMenuItem = { id: 33, name: 'Matcha Latte', style: 'Specials', price: 4.5, description: 'Camillia Matcha, Steamed Milk' }
const dirtyChai: FoodMenuItem = { id: 33, name: 'Dirty Chai', style: 'Specials', price: 4.5, description: 'Chai with Double Shot of Espresso' }
const policeThieves: FoodMenuItem = { id: 33, name: 'Police & Theives', style: 'Specials', price: 4.5, description: 'House Earl Gray Syrup, Lime Juice, Soda, Bitters' }

const earlGray: FoodMenuItem = { id: 33, name: 'Sri Lankan Earl Gray', style: 'Tea', price: 3-4, description: 'Sri Lankan Black Tea with Bergamot, Vanilla, and Cornflower' }
const assamBreakfast: FoodMenuItem = { id: 33, name: 'Assam Breakfast', style: 'Tea', price: 3-4, description: 'Black Tea from Banaspaty Garden in Assam, India' }
const ironGoddess: FoodMenuItem = { id: 33, name: 'Iron Goddess of Mercy', style: 'Tea', price: 3-4, description: 'Rolled Tea from Mr. Zhang Guo Hua\'s Farm in Juniang Province, China' }
const dragonPearl: FoodMenuItem = { id: 33, name: 'Dragon Pearl Jasmine', style: 'Tea', price: 3-4, description: 'Hand Rolled Chinese Green Tea Accented with Jasmine Flowers' }
const huiming: FoodMenuItem = { id: 33, name: 'Huiming Green Tea', style: 'Tea', price: 3-4, description: 'Chinese Style Green Tea From Zhejiang Province' }
const jingning: FoodMenuItem = { id: 33, name: 'Jingning Yin Zhen', style: 'Tea', price: 3-4, description: 'Chinese White Tea' }
const nanMei: FoodMenuItem = { id: 33, name: 'Nan Mei Wild Tea Buds', style: 'Tea', price: 3-4, description: 'Non- Caffinated Wild White Tea from Yunan Province' }
const sublimeChamomile: FoodMenuItem = { id: 33, name: 'Sublime Chamomile', style: 'Tea', price: 3-4, description: 'Chomomile, Rose Petals, Lemongrass' }
const lEclatante: FoodMenuItem = { id: 33, name: 'L\'Eclatante', style: 'Tea', price: 3-4, description: 'Lemon, Ginger, Lemongrass' }



const MobileCoffeeMenu = () => {
  return (
    <main style={{ backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em' }}>
      {/* Hero unit */}
        <Container sx={{ py: 0.0, px: 0.25, paddingTop: '0px' }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Espresso Beverages (hot or iced)</Typography>
            <MobileFoodItem key={espresso.id} menuItem={espresso} ></MobileFoodItem>
            <MobileFoodItem key={cortado.id} menuItem={cortado} ></MobileFoodItem>
            <MobileFoodItem key={latte.id} menuItem={latte} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Hot Coffee</Typography>
            <MobileFoodItem key={houseCoffee8.id} menuItem={houseCoffee8} ></MobileFoodItem>
            <MobileFoodItem key={houseCoffee12.id} menuItem={houseCoffee12} ></MobileFoodItem>
            <MobileFoodItem key={baristaCoffee8.id} menuItem={baristaCoffee8} ></MobileFoodItem>
            <MobileFoodItem key={baristaCoffee12.id} menuItem={baristaCoffee12} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Cold Coffee</Typography>
            <MobileFoodItem key={coldBrew12.id} menuItem={coldBrew12} ></MobileFoodItem>
            <MobileFoodItem key={theObvious.id} menuItem={theObvious} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Barista Specialty Drinks</Typography>
            <MobileFoodItem key={saturdayMorning.id} menuItem={saturdayMorning} ></MobileFoodItem>
            <MobileFoodItem key={angryGrad.id} menuItem={angryGrad} ></MobileFoodItem>
            <MobileFoodItem key={thePhD.id} menuItem={thePhD} ></MobileFoodItem>
            <MobileFoodItem key={notMilk.id} menuItem={notMilk} ></MobileFoodItem>
            <MobileFoodItem key={cardyB.id} menuItem={cardyB} ></MobileFoodItem>
            <MobileFoodItem key={cocoCaliente.id} menuItem={cocoCaliente} ></MobileFoodItem>
            <MobileFoodItem key={americola.id} menuItem={americola} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Cocktails 21+</Typography>
            <MobileFoodItem key={mimosa.id} menuItem={mimosa} ></MobileFoodItem>
            <MobileFoodItem key={french75.id} menuItem={french75} ></MobileFoodItem>
            <MobileFoodItem key={irishCoffee.id} menuItem={irishCoffee} ></MobileFoodItem>
            <MobileFoodItem key={twentyLeagues.id} menuItem={twentyLeagues} ></MobileFoodItem>            <MobileFoodItem key={adultSwim.id} menuItem={adultSwim} ></MobileFoodItem>
            <MobileFoodItem key={adultSwim.id} menuItem={adultSwim} ></MobileFoodItem>
            <MobileFoodItem key={blatantlyObvious.id} menuItem={blatantlyObvious} ></MobileFoodItem>
            <MobileFoodItem key={officeHours.id} menuItem={officeHours} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Cafe Food</Typography>
            <MobileFoodItem key={breakfastAuGratin.id} menuItem={breakfastAuGratin} ></MobileFoodItem>
            <MobileFoodItem key={coffeeCake.id} menuItem={coffeeCake} ></MobileFoodItem>
            <MobileFoodItem key={muffin.id} menuItem={muffin} ></MobileFoodItem>
            <MobileFoodItem key={bagel.id} menuItem={bagel} ></MobileFoodItem>
            <MobileFoodItem key={biscotti.id} menuItem={biscotti} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Non-Coffee</Typography>
            <MobileFoodItem key={milk.id} menuItem={milk} ></MobileFoodItem>
            <MobileFoodItem key={juice.id} menuItem={juice} ></MobileFoodItem>
            <MobileFoodItem key={hotChocolate.id} menuItem={hotChocolate} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Modifiers</Typography>
            <MobileFoodItem key={flavors.id} menuItem={flavors} ></MobileFoodItem>
            <MobileFoodItem key={oatMilk.id} menuItem={oatMilk} ></MobileFoodItem>
            <MobileFoodItem key={extraShot.id} menuItem={extraShot} ></MobileFoodItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Tea Specials</Typography>
            <MobileFoodItem key={londonFog.id} menuItem={londonFog} ></MobileFoodItem>
            <MobileFoodItem key={soulChaiTea.id} menuItem={soulChaiTea} ></MobileFoodItem>
            <MobileFoodItem key={matchaLattee.id} menuItem={matchaLattee} ></MobileFoodItem>
            <MobileFoodItem key={dirtyChai.id} menuItem={dirtyChai} ></MobileFoodItem>
            <MobileFoodItem key={policeThieves.id} menuItem={policeThieves} ></MobileFoodItem>
          </Box>          
          
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Loose Leaf Tea (Hot or Iced)</Typography>
            <MobileFoodItem key={earlGray.id} menuItem={earlGray} ></MobileFoodItem>
            <MobileFoodItem key={assamBreakfast.id} menuItem={assamBreakfast} ></MobileFoodItem>
            <MobileFoodItem key={ironGoddess.id} menuItem={ironGoddess} ></MobileFoodItem>
            <MobileFoodItem key={dragonPearl.id} menuItem={dragonPearl} ></MobileFoodItem>
            <MobileFoodItem key={huiming.id} menuItem={huiming} ></MobileFoodItem>
            <MobileFoodItem key={jingning.id} menuItem={jingning} ></MobileFoodItem>
            <MobileFoodItem key={nanMei.id} menuItem={nanMei} ></MobileFoodItem>
            <MobileFoodItem key={sublimeChamomile.id} menuItem={sublimeChamomile} ></MobileFoodItem>
            <MobileFoodItem key={lEclatante.id} menuItem={lEclatante} ></MobileFoodItem>
          </Box>
        </Container>
    </main>
  );
}

export default MobileCoffeeMenu;
