import React, { useEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';


//Read for more about CSS Grid layouts: https://mui.com/system/grid/

const DisplayTVFood = () => {
  const [customMenuData, setCustomMenuData] = useState<Array<UntappdMenuItem>>();

  async function getCustomMenuData(): Promise<Array<UntappdMenuItem>> {
    var unTappdItems: Array<UntappdMenuItem> = [];

    fetch(process.env.PUBLIC_URL + '/untappd-custom-menu.json')
      .then((r) => r.json())
      .then((data: UntappdMenuContainer) => {
        unTappdItems = (data as UntappdMenuContainer).menu.sections[1].items;

        console.log(JSON.stringify(unTappdItems));
        setCustomMenuData(unTappdItems);
        return unTappdItems;
      })

    return unTappdItems;
  }

  useEffect(() => {
    const c = getCustomMenuData(); //run it upon page load

    //reload menu data every 60 seconds
    setInterval(() => {
      const c = getCustomMenuData();
    }, 300 * 1000); //how often to pull new data in milliseconds

    }, []);


  return (  
      <main style={{ 
        backgroundColor: 'black', 
        overflowY: 'hidden', 
        paddingTop: '0em', 
        paddingLeft: '0em', 
        width: '80vw', // use viewport width
        height: '80vh', // use viewport height
        display: 'flex', // set as flex container
        flexDirection: 'row', // horizontal layout
        justifyContent: 'space-between', // Ensures flex items are evenly spaced without any gaps
        alignItems: 'stretch'  // Ensures flex items stretch to fill the container

 
    }}>
        <Box sx={{ 
        flexGrow: 1, 
        flexShrink: 0,
        flexBasis: '38vw',
        width: '38vw', 
        height: '80vh', 
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        border: 'none'
    }}>
        {customMenuData && customMenuData.length > 0 && 
            <img style={{ 
                transform: 'rotate(-90deg)', 
                height: '42vw', // setting the height which is originally its width
                width: '78vh',
                display: 'block',
                margin: 0,
                border: 'none'
            }} src={customMenuData[0].label_image} />}
    </Box>
    <Box sx={{ 
        flexGrow: 1, 
        flexShrink: 0,
        flexBasis: '38vw',
        width: '38vw', 
        height: '80vh', 
        overflow: 'hidden',
        margin: 0,
        padding: 0,
        border: 'none'
    }}>
        {customMenuData && customMenuData.length > 1 && 
            <img style={{ 
                transform: 'rotate(-90deg)', 
                height: '42vw', 
                width: '78vh',                
                display: 'block',
                margin: 0,
                border: 'none'
            }} src={customMenuData[1].label_image} />}
    </Box>

      </main>
  );
}

export default DisplayTVFood;
