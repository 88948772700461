import './App.css';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FoodMenuItem } from './menu-types';


export interface FoodItemBoxProps extends BoxProps {
  menuItem: FoodMenuItem;
}
const MobileFoodItem = (props: FoodItemBoxProps) => {
  const { menuItem, sx, ...other } = props;
  const isSuperLong: boolean = menuItem.name.length > 24 ? true : false;
  //const bgColorPallette: string = 'lager';

if (menuItem.name.length == 0) {
  return <Box sx={{
    width: '100%',
    height: '158px',color: '#222',
    bgcolor: '#fff',
    textAlign: 'center', 
    verticalAlign: 'middle',
    borderRadius: 1,
    paddingTop: '50px'
    }}>
      <img width={70} height={60} src='/empty_tap_line.png' />
    </Box>
}

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '80px',
        color: '#222',
        bgcolor: '#fff',
        borderRadius: 1,
        '& > .MuiBox-root > .MuiBox-root': {
          px: 0.5,
          py: 0.5,
          borderRadius: 0,
          fontSize: '0.875rem',
          fontWeight: '700',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          px: 0,
          py: 0,
          gridTemplateColumns: 'auto 60px',
          gap: 0.0,
          gridAutoRows: 'auto auto auto',
          gridTemplateAreas: `"main prices" "desc desc" "info info"`
        }}
      >        
        <Box sx={{ gridArea: 'main' }}>
          <Typography variant="h5" component="h2" fontWeight="500" fontSize={'1.6rem'}>{menuItem.name}</Typography>
        </Box>
        
        <Box sx={{ gridArea: 'desc', minWidth: 0, textOverflow: 'ellipsis', display: 'inline-block', overflow: 'hidden' }}>
          <Typography style={{ whiteSpace: "pre-line" }}>{menuItem.description.replace('\\\\', '\n').replace('||', '\n')}</Typography>
        </Box>

        <Box sx={{ gridArea: 'info', display: 'inline', bottom: '0px' }}>
        </Box>

        <Box sx={{ gridArea: 'prices', textAlign: 'right', marginLeft: '0px', marginRight: '0px', marginTop: '4px' }}>
        <Box sx={{borderRadius: 1, 'height': '32px', paddingRight: '6px', marginBottom: '4px', paddingTop: '5px', backgroundColor: '#303030', color: 'white'}} > ${menuItem.price}</Box>
        </Box>

      </Box>
    </Box>
  );
}

export default MobileFoodItem;
