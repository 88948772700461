import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer, UntappdCustomMenuContainer, UntappdCustomMenuItem } from './untappd-types';
import DisplayItemV3 from './DisplayItemV3';
import Typography from '@mui/material/Typography';
import { Routes, Route, useParams } from 'react-router-dom';
import { ThirtyFpsSelectOutlined } from '@mui/icons-material';

//Read for more about CSS Grid layouts: https://mui.com/system/grid/

type DisplaySectionParams = {
  sectionNumber: string;
};


const DisplayTV2 = () => {
  const [menuData, setMenuData] = useState<Array<UntappdMenuItem>>();
  const [customMenuData, setCustomMenuData] = useState<Array<UntappdMenuItem>>();
  let sectionNumber:number = 2;
  const [itemStart, setItemStart] = useState<number>(16);
  const [itemEnd, setItemEnd] = useState<number>(32);

  async function getMenuData(): Promise<Array<UntappdMenuItem>> {
    var unTappdItems: Array<UntappdMenuItem> = [];
    var items: Array<UntappdMenuItem> = [];
   

    fetch(process.env.PUBLIC_URL + '/untappd-menu.json')
      .then((r) => r.json())
      .then((data: UntappdMenuContainer) => {
        //console.log(JSON.stringify(data));
        (data as UntappdMenuContainer).menu.sections.forEach((section) => {
          unTappdItems = unTappdItems.concat(section.items);
        });

        unTappdItems.forEach(x => x.tap_number_int = parseInt(x.tap_number));
        for (var i = 1; i < 33; i++)
        {
          var found = unTappdItems.filter(x => x.tap_number_int == i);
          if (found.length > 0)
          {
            items[i] = found[0];
          }
          else
          {
            var blank : UntappdMenuItem = { id: i, description: i.toString(), style: "", tap_number: i.toString(), tap_number_int: i, name: "", type: "", abv: "", section_id: 0, containers: [], label_image: "" };
            items[i] = blank;
          }
        }
        //console.log(JSON.stringify(items));
        setMenuData(items);
        return items;
      })

    return items;
  }

  async function getCustomMenuData(): Promise<Array<UntappdMenuItem>> {
    var unTappdItems: Array<UntappdMenuItem> = [];

    fetch(process.env.PUBLIC_URL + '/untappd-custom-menu.json')
      .then((r) => r.json())
      .then((data: UntappdMenuContainer) => {
        unTappdItems = (data as UntappdMenuContainer).menu.sections[0].items;

        console.log(JSON.stringify(unTappdItems));
        setCustomMenuData(unTappdItems);
        return unTappdItems;
      })

    return unTappdItems;
  }

  function transposeTo2Column(items : Array<UntappdMenuItem>) : Array<UntappdMenuItem> {
    var twoCol: Array<UntappdMenuItem> = [];

    twoCol[1] = items[0];
    twoCol[2] = items[5];
   
    twoCol[3] = items[1];
    twoCol[4] = items[6];
    
    twoCol[5] = items[2];
    twoCol[6] = items[7];
    
    twoCol[7] = items[3];
    twoCol[8] = items[8];
   
    twoCol[9] = items[4];
    twoCol[10] = items[9];
  
    //console.log(JSON.stringify(twoCol));
    return twoCol;
  }

  useEffect(() => {
    const m = getMenuData(); //run it upon page load
    const c = getCustomMenuData(); //run it upon page load

    //reload menu data every 60 seconds
    setInterval(() => {
      const m = getMenuData(); //call function to read in the json file and set menu data object
      const c = getCustomMenuData();
    }, 300 * 1000); //how often to pull new data in milliseconds

    }, []);


  return (  
      <main style={{backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em'}}>
        {/* Hero unit */}
        <ThemeProvider
            theme={{
              palette: {
                primary: {
                  sour: '#DB523A',
                  IPA: '#5BB165',
                  lager: '#E7A550',
                  stout: '#782382',
                  other: '#3E1FCE'
                },
              },
            }}
        >
        <Container sx={{ py: 0.0, px: 0 }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridAutoFlow: "row", gridTemplateColumns: '492px 492px', columnGap: 1, rowGap: 1, px: 0 }}>
            {menuData && transposeTo2Column(menuData.slice(itemStart, itemEnd)).map((menu_item) => <DisplayItemV3 key={menu_item.id} menuItem={menu_item} ></DisplayItemV3>)}
          </Box>
          <Box sx={{width: '492px', top: '8px', left: '1024px', position: 'fixed'}} >
          {customMenuData && customMenuData.length > 0 && <img width={'492px'} height={'270px'} src={customMenuData[0].label_image} /> }
          {customMenuData && customMenuData.length > 1 && <img width={'492px'} height={'270px'} src={customMenuData[1].label_image} /> }
          {customMenuData && customMenuData.length > 2 && <img width={'492px'} height={'270px'} src={customMenuData[2].label_image} /> }
          </Box>
        </Container>
        <Container sx={{ py: 0.0, bgcolor: '#333', color: '#fff', height: '30px', width: '100%', bottom: '0px', position: 'absolute' }} maxWidth={false}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto auto auto auto auto', gridTemplateRows: 'auto', columnGap: 2, rowGap: 1 }}>
                <Box><Typography sx={{ color: 'white', textAlign: 'center'}} variant="h5" component="h5">Beer Styles:</Typography></Box>
                <Box sx={{ bgcolor: 'primary.IPA', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>IPA</Box>
                <Box sx={{ bgcolor: 'primary.sour', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>Sour</Box>
                <Box sx={{ bgcolor: 'primary.lager', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>Lager</Box>
                <Box sx={{ bgcolor: 'primary.stout', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>Stout</Box>
                <Box sx={{ bgcolor: 'primary.other', textAlign: 'center', height: '50px', fontWeight: 'bold', paddingTop: '5px'}}>Other</Box>
            </Box>
        </Container>
        </ThemeProvider>
      </main>
  );
}

export default DisplayTV2;
