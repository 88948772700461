import React from 'react';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DisplayTV1 from './DisplayTV1';
import DisplayTV2 from './DisplayTV2';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import DisplayTVFood from './DisplayTVFood';

function App() {

  return (
      <CssBaseline>
        <ThemeProvider theme={createTheme()}>
        <Router>
            <Routes>
              <Route path="/" element={<DisplayTV1 />} />
              <Route path="/tv/1" element={<DisplayTV1 />} />
              <Route path="/tv/2" element={<DisplayTV2 />} />
              <Route path="/tv/food" element={<DisplayTVFood />} />
              <Route path="/mobile/" element={<MobileMenu />} />
            </Routes>
        </Router>
        </ThemeProvider>
      </CssBaseline>
  );
}

export default App;
